import groupBuying from './groupBuying'

export default {
  mixins: [groupBuying],
  inject: ['$currency'],
  computed: {
    groupBuying () {
      return this.data.groupbuying || { status: '' }
    },
    specIndex () {
      return this.orderedSpecIndex
    },
    order () {
      return this.data
    },
  },
}
