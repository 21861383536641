const STATUS_WAITING = 0
const STATUS_GOING = 1
const STATUS_CONFIRMED = 2
const STATUS_FINISHED = 3
const STATUS_PENDING = 4
const STATUS_CANCELLED = 5

export default {
  computed: {
    STATUS_WAITING: () => STATUS_WAITING,
    STATUS_GOING: () => STATUS_GOING,
    STATUS_CONFIRMED: () => STATUS_CONFIRMED,
    STATUS_FINISHED: () => STATUS_FINISHED,
    STATUS_PENDING: () => STATUS_PENDING,
    STATUS_CANCELLED: () => STATUS_CANCELLED,
    statusCategory () {
      return +this.groupBuying.status.slice(0, 1)
    },
    product () {
      // if (!this.groupBuying.products) return {}
      // return this.groupBuying.products[0] || {}
      return this.groupBuying.groupbuying_product || {}
    },
    specs () {
      // return this.product.skus || []
      if (!this.spec.id) return []
      return [this.spec]
    },
    spec () {
      // return this.specs[this.specIndex] || {}
      return this.groupBuying.groupbuying_sku || {}
    },
    orderedSpecIndex () {
      // return this.specs.findIndex(({ id }) => id === this.order.sku_id)
      if (!this.spec.id) return -1
      return 0
    },
    images () {
      const images = this.product.images || []

      return images.map(({ pic_url }) => pic_url)
    },
    coverImage () {
      return this.images[0]
    },
    productImages() {
      return this.images.slice(1)
    },
    group () {
      return this.groupBuying.group || {}
    },
  },
}
