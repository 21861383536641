<template>
  <div class="s-order-item">
    <div class="s-order-item-image" :class="order.is_original_org ? 's-bg-secondary': 's-bg-warning'">
      <div class="s-order-item-image-wrapper">
        <img :src="image">
      </div>
      <p class="s-py-2 s-text-center">
        <img src="@/assets/images/liff/group-buying/poya.svg" v-if="order.is_original_org">
        <img src="@/assets/images/liff/group-buying/poya-home.svg" v-else>
      </p>
    </div>
    <div class="s-order-item-info s-space-y-2">
      <p class="s-text-gray-darker s-font-semibold">{{ product.title }}</p>
      <p class="s-font-bold">
        <span class="s-text-gray-darker s-mr-3">{{ order.quantity }} 組</span>
        <span class="s-text-secondary">{{ $currency(data.total_price) }}</span>
      </p>
      <p class="s-text-gray s-text-xs s-whitespace-nowrap">訂單編號 <span v-if="order.order">{{ order.order.outer_order_number }}</span></p>
      <p class="s-text-gray s-text-xs">{{ order.groupbuying.group.name }}</p>
    </div>
    <div class="s-order-item-tag">
      <span class="s-tag s-tag-secondary s-mb-auto" v-if="shouldShowTag">{{ order.status_label }}</span>
      <!-- <a class="s-tag s-tag-secondary s-mb-auto" href="#" @click.prevent="$refs.modal.open()" v-if="invoice">發票明細</a> -->
      <a class="s-link s-link-secondary s-mt-auto" :href="returnURL" v-if="returnLabel">{{ returnLabel }}</a>
    </div>
    <Modal title="發票明細" ref="modal" no-actions v-if="invoice">
      <ul class="s-text-gray-darker s-text-sm s-space-y-3">
        <li>發票號碼：{{ invoice.invoice_number }}</li>
        <li>發票日期：{{ invoiceDateTime.format('YYYY/MM/DD') }}</li>
        <li>發票時間：{{ invoiceDateTime.format('HH:mm:ss') }}</li>
        <li>發票總金額：{{ $currency(invoice.total_price) }}</li>
      </ul>
    </Modal>
  </div>
</template>

<script>
  import moment from 'moment'
  import Modal from '@/components/Page/Liff/Shared/Modal'
  import order from '@/mixins/liff/groupBuying/order'

  export default {
    mixins: [order],
    components: { Modal },
    inject: ['$currency'],
    props: {
      data: {
        type: Object,
        required: true,
      },
      status: {
        type: String,
        required: true,
      },
    },
    computed: {
      shouldShowTag () {
        return this.order.status_label !== '已取貨'
      },
      images () {
        return this.product.images || [{}]
      },
      image () {
        return this.images[0].pic_url
      },
      invoice () {
        return this.data.invoice
      },
      invoiceDateTime () {
        return moment(this.invoice.invoice_date)
      },
      returnLabel () {
        if (this.data.status === 'WaitingToShipping') return '取消訂單'
        if (this.status === 'completed') return '申請退貨'
        return null
      },
      returnURL () {
        if (this.order.is_original_org) return 'https://www.poyabuy.com.tw/V2/TradesOrder/TradesOrderList?shopId=40916'
        return 'https://www.poyahomebuy.com.tw/V2/TradesOrder/TradesOrderList?shopId=40917'
      }
    },
  }
</script>

<style scoped>
  .s-order-item {
    padding: 0.75rem 0;
    border-bottom: solid 1px var(--s-gray-lighter);
    display: flex;
    min-height: 8rem;
  }

  .s-order-item-image {
    flex-shrink: 0;
    margin-right: 0.5rem;
    border-radius: 0.375rem;
    font-size: 0;
    align-self: flex-start;
  }

  .s-order-item-image-wrapper {
    width: 5rem;
    height: 5rem;
    margin: 0.25rem;
    margin-bottom: 0;
    border-radius: 0.5rem;
    background-color: var(--s-white);
  }

  .s-order-item-image-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.5rem;
  }

  .s-order-item-info {
    margin-right: 0.5rem;
  }

  .s-order-item-tag {
    flex-shrink: 0;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 5.5rem;
  }

  .s-tag {
    text-align: center;
  }
</style>
