<template>
  <ul class="s-orders-tab">
    <li>
      <router-link :to="{ name: 'LiffGroupBuyingOrdersStatus', params: { status: 'processing' } }">
        跟團中
      </router-link>
    </li>
    <li>
      <router-link :to="{ name: 'LiffGroupBuyingOrdersStatus', params: { status: 'completed' } }">
        已取貨
      </router-link>
    </li>
    <li>
      <router-link :to="{ name: 'LiffGroupBuyingOrdersStatus', params: { status: 'invalid' } }">
        已失效
      </router-link>
    </li>
  </ul>
</template>

<script>
  export default {
    //
  }
</script>

<style scoped>
  .s-orders-tab {
    display: flex;
    align-items: flex-end;
    background-color: var(--s-white);
  }

  .s-orders-tab li {
    flex: 1 1 0;
  }

  .s-orders-tab li a {
    display: block;
    padding: 0.75rem 0;
    text-align: center;
    border-bottom: solid 1px var(--s-gray-lighter);
  }

  .s-orders-tab li a.active {
    color: var(--s-secondary);
    font-weight: var(--s-weight-semibold);
    border-bottom: solid 2px var(--s-secondary);
    padding-bottom: calc(0.75rem - 1px);
  }
</style>
